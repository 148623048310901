<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-tabs
      v-model="tab"
      color="purple"
    >
      <v-tab
        key="personal"
        class="text-capitalize"
      >
        Personal
      </v-tab>

      <v-tab
        key="perusahaan"
        class="text-capitalize"
      >
        Perusahaan
      </v-tab>

      <v-tab
        key="verifikasibank"
        class="text-capitalize"
      >
        Verifikasi Bank
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- TAB PERSONAL -->
      <v-tab-item key="personal">
        <template v-if="customerView">
          <div
            id="personal-tab-container"
            style="padding: 12px;"
          >
            <v-data-table
              :headers="headers"
              :items="customers"
              :items-per-page="25"
              class="elevation-0"
              mobile-breakpoint="100"
              hide-default-footer
            >
              <template v-slot:top>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="mt-4 px-8 pt-4 pb-10"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Cari"
                      color="purple"
                      single-line
                      hide-details
                      clearable
                      outlined
                      dense
                      @keyup.enter.native="filterData"
                      @click:append="filterData"
                      @click:clear="filterClear"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.name="{ item }">
                <span v-if="item.reseller === true">
                  <span class="customer-reseller">
                    {{ item.name }}*
                  </span>
                </span>
                <span v-else>
                  <span class="customer-regular">
                    {{ item.name }}
                  </span>
                </span>
              </template>

              <template v-slot:item.trapoin_saldo="{ item }">
                {{ item.trapoin_saldo | dotThousandSeparator }}
              </template>

              <template v-slot:item.trx_total="{ item }">
                <div
                  style="
                  display: flex;
                  justify-content: space-between;
                "
                >
                  <span>
                    Rp
                  </span>

                  <span>
                    {{ item.trx_total | dotThousandSeparator }}
                  </span>
                </div>
              </template>

              <template v-slot:item.verification_dat="{ item }">
                <template v-if="item.verification_dat.btn_enable">
                  <v-btn
                    width="110px"
                    height="32px"
                    style="
                    background: #7A1878;
                  "
                    @click="showDialogVerifikasiPersonal(item)"
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>

                <template v-if="!item.verification_dat.btn_enable && !item.verified">
                  <v-btn
                    width="110px"
                    height="32px"
                    disabled
                    style="
                    background: #E6E6E6;
                  "
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>

                <template v-if="item.verified">
                  <v-btn
                    class="elevation-0"
                    width="110px"
                    height="32px"
                    style="
                    background: #FFFFFF;
                    border: 1px solid #5DB025;
                    outline: none;
                    cursor: default;
                  "
                  >
                    <v-icon color="green">
                      mdi-check
                    </v-icon>
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #5DB025;
                    "
                    >
                      Terverifikasi
                    </span>
                  </v-btn>
                </template>
              </template>

              <template v-slot:item.actions="{ item }">
                <div
                  class="action-container"
                  style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                  @click="toPersonalDetail(item)"
                >
                  <v-icon
                    class="action-icon"
                    style="font-size: 16px"
                  >
                    fas fa-eye
                  </v-icon>
                </div>
              </template>

              <template v-slot:footer>
                <div class="text-center my-5 py-3">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    color="purple"
                    :total-visible="5"
                    @input="onChangePage"
                  />
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
                  </span>
                </div>
              </template>
            </v-data-table>
          </div>
        </template>

        <template v-else>
          <v-row>
            <v-col
              class="text-center py-16 font-common-bold"
              style="font-size: 24px"
            >
              Tidak ada akses
            </v-col>
          </v-row>
        </template>
      </v-tab-item>

      <!-- TAB PERUSAHAAN -->
      <v-tab-item key="perusahaan">
        <template v-if="customerCompanyView">
          <div
            id="personal-tab-container"
            style="padding: 12px;"
          >
            <v-data-table
              :headers="headerPerusahaan"
              :items="perusahaan"
              :items-per-page="25"
              class="elevation-0"
              mobile-breakpoint="100"
              hide-default-footer
            >
              <template v-slot:top>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="mt-4 px-8 pt-4 pb-10"
                  >
                    <v-text-field
                      v-model="searchPerusahaan"
                      append-icon="mdi-magnify"
                      label="Cari Perusahaan"
                      color="purple"
                      single-line
                      hide-details
                      outlined
                      dense
                      clearable
                      @keyup.enter.native="filterDataPerusahaan"
                      @click:append="filterDataPerusahaan"
                      @click:clear="filterClearPerusahaan"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.name_legal="{ item }">
                <span v-if="item.name_legal === null">
                  -
                </span>
                <span v-else>
                  {{ item.name_legal }}
                </span>
              </template>

              <template v-slot:item.wallet_saldo="{ item }">
                {{ (item.trapoin_saldo || 0) | dotThousandSeparator }}
              </template>

              <template v-slot:item.trx_total="{ item }">
                <div
                  style="
                  display: flex;
                  justify-content: space-between;
                "
                >
                  <span>
                    Rp
                  </span>

                  <span>
                    {{ (item.trx_total || 0) | dotThousandSeparator }}
                  </span>
                </div>
              </template>

              <template v-slot:item.verification_dat="{ item }">
                <template v-if="item.verification_dat.btn_enable">
                  <v-btn
                    width="110px"
                    height="32px"
                    style="
                    background: #7A1878;
                  "
                    @click="showDialogVerifikasiPerusahaan(item)"
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>

                <template v-if="!item.verification_dat.btn_enable && !item.verified">
                  <v-btn
                    width="110px"
                    height="32px"
                    disabled
                    style="
                    background: #E6E6E6;
                  "
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>

                <template v-if="item.verified">
                  <v-btn
                    width="110px"
                    height="32px"
                    style="
                    background: #FFFFFF;
                    border: 1px solid #5DB025;
                    outline: none;
                    cursor: default;
                  "
                  >
                    <v-icon color="green">
                      mdi-check
                    </v-icon>
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #5DB025;
                    "
                    >
                      Terverifikasi
                    </span>
                  </v-btn>
                </template>
              </template>

              <template v-slot:item.actions="{ item }">
                <div
                  class="action-container"
                  style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                  @click="toPersonalPerusahaanDetail(item)"
                >
                  <v-icon
                    class="action-icon"
                    style="font-size: 16px"
                  >
                    fas fa-eye
                  </v-icon>
                </div>
              </template>

              <template v-slot:footer>
                <div class="text-center my-5 py-3">
                  <v-pagination
                    v-model="pagePerusahaan"
                    :length="pageCountPerusahaan"
                    color="purple"
                    :total-visible="5"
                    @input="onChangePagePerusahaan"
                  />
                  <!-- <span style="font-size: 12px; font-style: italic;">
                  Page {{ pagePerusahaan }} of {{ pageCountPerusahaan || 1 }}
                </span> -->
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCountPerusahaan + ' dari ' + itemTotalPerusahaan + ' ditampilkan' }}
                  </span>
                </div>
              <!-- <v-row class="mt-n16">
                <v-col class="text-right pr-8">
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCountPerusahaan + ' dari ' + itemTotalPerusahaan + ' ditampilkan' }}
                  </span>
                </v-col>
              </v-row> -->
              </template>
            </v-data-table>
          </div>
        </template>

        <template v-else>
          <v-row>
            <v-col
              class="text-center py-16 font-common-bold"
              style="font-size: 24px"
            >
              Tidak ada akses
            </v-col>
          </v-row>
        </template>
      </v-tab-item>

      <!-- TAB VERIFIKASI BANK -->
      <v-tab-item key="verifikasibank">
        <template v-if="customerBankVerificationView">
          <div
            id="personal-tab-container"
            style="padding: 12px;"
          >
            <v-data-table
              :headers="headerVerifikasiBank"
              :items="verifikasibank"
              :items-per-page="25"
              class="elevation-0"
              mobile-breakpoint="100"
              hide-default-footer
            >
              <template v-slot:top>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="mt-4 px-8 pt-4 pb-10"
                  >
                    <v-text-field
                      v-model="searchVerifikasiBank"
                      append-icon="mdi-magnify"
                      label="Cari..."
                      color="purple"
                      single-line
                      hide-details
                      clearable
                      outlined
                      dense
                      @keyup.enter.native="filterDataVerifikasiBank"
                      @click:append="filterDataVerifikasiBank"
                      @click:clear="filterClearVerifikasiBank"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.wallet_saldo="{ item }">
                {{ (item.trapoin_saldo || 0) | dotThousandSeparator }}
              </template>

              <template v-slot:item.trx_total="{ item }">
                <div
                  style="
                  display: flex;
                  justify-content: space-between;
                "
                >
                  <span>
                    Rp
                  </span>

                  <span>
                    {{ (item.trx_total || 0) | dotThousandSeparator }}
                  </span>
                </div>
              </template>

              <template v-slot:item.verification_dat="{ item }">
                <template v-if="item.verification_dat.btn_enable">
                  <v-btn
                    width="110px"
                    height="32px"
                    style="
                    background: #7A1878;
                  "
                    @click="showDialogVerifikasiPerusahaan(item)"
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>

                <template v-if="!item.verification_dat.btn_enable && !item.verified">
                  <v-btn
                    width="110px"
                    height="32px"
                    disabled
                    style="
                    background: #E6E6E6;
                  "
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>

                <template v-if="item.verified">
                  <v-btn
                    width="110px"
                    height="32px"
                    style="
                    background: #FFFFFF;
                    border: 1px solid #5DB025;
                    outline: none;
                    cursor: default;
                  "
                  >
                    <v-icon color="green">
                      mdi-check
                    </v-icon>
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #5DB025;
                    "
                    >
                      Terverifikasi
                    </span>
                  </v-btn>
                </template>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="elevation-0"
                  width="110px"
                  height="32px"
                  style="
                    background: #7A1878;
                  "
                  @click="showDialogVerifikasiBank(item)"
                >
                  <span
                    class="text-capitalize"
                    style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                  >
                    Verifikasi
                  </span>
                </v-btn>
              </template>

              <template v-slot:footer>
                <div class="text-center my-5 py-3">
                  <v-pagination
                    v-model="pageVerifikasiBank"
                    :length="pageCountVerifikasiBank"
                    color="purple"
                    :total-visible="5"
                    @input="onChangePagePerusahaan"
                  />
                  <!-- <span style="font-size: 12px; font-style: italic;">
                  Page {{ pageVerifikasiBank }} of {{ pageCountVerifikasiBank || 1 }}
                </span> -->
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCountVerifikasiBank + ' dari ' + itemTotalVerifikasiBank + ' ditampilkan' }}
                  </span>
                </div>
              <!-- <v-row class="mt-n16">
                <v-col class="text-right pr-8">
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCountVerifikasiBank + ' dari ' + itemTotalVerifikasiBank + ' ditampilkan' }}
                  </span>
                </v-col>
              </v-row> -->
              </template>
            </v-data-table>
          </div>
        </template>

        <template v-else>
          <v-row>
            <v-col
              class="text-center py-16 font-common-bold"
              style="font-size: 24px"
            >
              Tidak ada akses
            </v-col>
          </v-row>
        </template>
      </v-tab-item>
    </v-tabs-items>

    <!-- DIALOG VERIFIKASI PERSONAL -->
    <v-dialog
      v-model="dialogVerifikasiPersonal"
      max-width="600"
    >
      <template v-if="customerVerification">
        <v-card class="px-4 py-4">
          <v-row>
            <v-col>
              <span style="font-weight: bold">
                Verifikasi Personal
              </span>
            </v-col>

            <v-col class="text-right">
              <span
                style="cursor: pointer"
                @click="dialogVerifikasiPersonal = false"
              >
                X
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Nama
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ verifikasiNama || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Email
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ verifikasiEmail || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Dokumen
            </v-col>

            <v-col
              class="d-flex font-common-bold"
              cols="4"
              sm="4"
              style="flex-direction: column"
            >
              <span>
                KTP
              </span>

              <template v-if="verifikasiKTP">
                <v-img
                  :src="verifikasiKTP"
                  contain
                  style="cursor: pointer"
                  @click="downloadAttachment(verifikasiKTP)"
                />
              </template>
            </v-col>

            <v-col
              class="d-flex font-common-bold"
              cols="4"
              sm="4"
              style="flex-direction: column"
            >
              <span>
                Selfie KTP
              </span>

              <template v-if="verifikasiSelfie">
                <v-img
                  :src="verifikasiSelfie"
                  contain
                  style="cursor: pointer"
                  @click="downloadAttachment(verifikasiSelfie)"
                />
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="pt-5 font-common"
              cols="4"
              sm="4"
            >
              Nomor NIK
            </v-col>

            <v-col
              cols="8"
              sm="8"
            >
              <v-text-field
                v-model="verifikasiNoNIK"
                label="Masukkan Nomor NIK"
                color="purple"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row class="mt-n5">
            <v-col
              class="font-common"
              cols="4"
              sm="4"
              style="
              display: flex;
              flex-direction: column;
            "
            >
              <span>
                Keterangan
              </span>

              <span
                class="font-common"
                style="
                font-size: 12px;
                color: #808080;
              "
              >
                *Wajib diisi bila menolakk
              </span>
            </v-col>

            <v-col
              cols="8"
              sm="8"
              style="
              display: flex;
              flex-direction: column;
            "
            >
              <div>
                <input
                  id="alasan-1"
                  type="checkbox"
                  name="alasan-1"
                  value="Foto KTP dan Foto KTP Selfie anda sama"
                >
                <label
                  class="font-common"
                  for="alasan-1"
                  style="font-size: 12px"
                >
                  Foto KTP dan Foto KTP Selfie anda sama
                </label>
              </div>

              <div class="mt-2">
                <input
                  id="alasan-2"
                  type="checkbox"
                  name="alasan-2"
                  value="Foto yang dikirimkan blur atau terlalu terang/gelap"
                >
                <label
                  class="font-common"
                  for="alasan-2"
                  style="font-size: 12px"
                >
                  Foto yang dikirimkan blur atau terlalu terang/gelap
                </label>
              </div>

              <div class="mt-3">
                <input
                  id="alasan-3"
                  type="checkbox"
                  name="alasan-3"
                  value="NIK dan informasi lainnya pada KTP terlihat jelas dan terbaca"
                >
                <label
                  class="font-common"
                  for="alasan-3"
                  style="font-size: 12px"
                >
                  NIK dan informasi lainnya pada KTP tidak terlihat jelas dan tidak terbaca
                </label>
              </div>

              <div class="mt-4">
                <input
                  id="alasan-4"
                  type="checkbox"
                  name="alasan-4"
                  value="Sebagian dari wajah Anda tertutup atau menggunakan masker, topi, atau kacamata gelap"
                >
                <label
                  class="font-common"
                  for="alasan-4"
                  style="font-size: 12px"
                >
                  Sebagian dari wajah Anda tertutup atau menggunakan masker, topi, atau kacamata gelap
                </label>
              </div>

            <!-- <div>
              <v-textarea
                id="verifikasi-keterangan"
                v-model="verifikasiKeterangan"
                label="Masukkan Keterangan"
                color="purple"
                no-resize
                outlined
              />
            </div> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right">
              <v-btn
                class="text-capitalize"
                style="
                width: 100px;
                height: 46px;
                background: #7A1878;
              "
                @click="verifikasiPersonal(1)"
              >
                Verifikasi
              </v-btn>

              <v-btn
                class="text-capitalize"
                style="
                width: 71px;
                height: 46px;
                background: #EA3E3A;
              "
                @click="verifikasiPersonal(0)"
              >
                Tolak
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>

      <template v-else>
        <v-card class="px-4 py-4">
          <v-row>
            <v-col>
              <span style="font-weight: bold">
                Verifikasi Personal
              </span>
            </v-col>

            <v-col class="text-right">
              <span
                style="cursor: pointer"
                @click="dialogVerifikasiPersonal = false"
              >
                X
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="text-center py-16 font-common-bold"
              style="font-size: 24px"
            >
              <!-- Tidak ada akses -->
              <v-img
                contain
                src="@/assets/no-akses.svg"
              />
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>

    <!-- DIALOG VERIFIKASI PERUSAHAAN -->
    <v-dialog
      v-model="dialogVerifikasiPerusahaan"
      max-width="600"
    >
      <template v-if="customerCompanyVerification">
        <v-card class="px-4 py-4">
          <v-row>
            <v-col>
              <span
                class="font-common-black-bold"
                style="font-weight: bold"
              >
                Verifikasi Perusahaan
              </span>
            </v-col>

            <v-col class="text-right">
              <span
                style="cursor: pointer"
                @click="dialogVerifikasiPerusahaan = false"
              >
                X
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Nama Merek
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ verifikasiPerusahaan.name || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Nama Legal
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ verifikasiPerusahaan.name_legal || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Nama Pemilik
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
            >
              {{ verifikasiPerusahaan.owner_name || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="4"
              sm="4"
            >
              Dokumen
            </v-col>

            <v-col
              class="font-common-bold"
              cols="8"
              sm="8"
              style="
              display: flex;
              flex-direction: column;
            "
            >
              <template v-if="verifikasiPerusahaan.verification_dat.documents !== null">
                <div
                  v-for="(v, i) in verifikasiPerusahaan.verification_dat.documents"
                  :key="'document-verification-' + i"
                >
                  <span>
                    {{ v.name }}
                  </span>

                  <v-img
                    :src="v.file_path_view_only"
                    width="230px"
                    height="153px"
                    contain
                    style="cursor: pointer"
                    @click="downloadAttachment(v.file_path_view_only)"
                  />
                </div>
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="pt-5 font-common"
              cols="4"
              sm="4"
            >
              NPWP
            </v-col>

            <v-col
              cols="8"
              sm="8"
            >
              <v-text-field
                v-model="verifikasiNPWP"
                label="Masukkan NPWP"
                color="purple"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row class="mt-n5">
            <v-col
              class="font-common"
              cols="4"
              sm="4"
              style="
              display: flex;
              flex-direction: column;
            "
            >
              <span>
                Keterangan
              </span>

              <span
                style="
                font-size: 12px;
                color: #808080;
              "
              >
                *Wajib diisi bila menolak
              </span>
            </v-col>

            <v-col
              cols="8"
              sm="8"
            >
              <v-textarea
                id="verifikasi-keterangan-perusahaan"
                v-model="verifikasiKeteranganPerusahaan"
                label="Masukkan Keterangan"
                color="purple"
                no-resize
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right">
              <v-btn
                class="text-capitalize"
                style="
                width: 100px;
                height: 46px;
                background: #7A1878;
              "
                @click="verifikasiAkunPerusahaan(1)"
              >
                Verifikasi
              </v-btn>

              <v-btn
                class="text-capitalize"
                style="
                width: 71px;
                height: 46px;
                background: #EA3E3A;
              "
                @click="verifikasiAkunPerusahaan(0)"
              >
                Tolak
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>

      <v-card class="px-4 py-4">
        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Verifikasi Perusahaan Personal
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogVerifikasiPerusahaan = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="text-center py-16 font-common-bold"
            style="font-size: 24px"
          >
            <!-- Tidak ada akses -->
            <v-img
              contain
              src="@/assets/no-akses.svg"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG VERIFIKASI BANK -->
    <v-dialog
      v-model="dialogVerifikasiBank"
      max-width="600"
    >
      <v-card
        v-if="selectedVerifikasiBank !== null"
        class="px-4 pt-4 pb-10"
      >
        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Verifikasi Bank Penyewa
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogVerifikasiBank = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nama
          </v-col>

          <v-col
            class="font-common-bold"
            cols="8"
            sm="8"
          >
            {{ selectedVerifikasiBank.name || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nama Bank
          </v-col>

          <v-col
            class="font-common-bold"
            cols="8"
            sm="8"
          >
            {{ selectedVerifikasiBank.bank_name || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nomor Rekening
          </v-col>

          <v-col
            class="font-common-bold"
            cols="8"
            sm="8"
          >
            {{ selectedVerifikasiBank.bank_number || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Atas Nama
          </v-col>

          <v-col
            class="font-common-bold"
            cols="8"
            sm="8"
          >
            {{ selectedVerifikasiBank.bank_owner || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Riwayat Bank
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Nama Bank
                    </th>
                    <th class="text-left">
                      Nomor Rekening
                    </th>
                    <th class="text-left">
                      Atas Nama
                    </th>
                    <th class="text-left">
                      Tanggal Perubahan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(v, i) in selectedVerifikasiBank.bank_historical"
                    :key="'bank-history-' + i"
                  >
                    <td>{{ v.bank_name }}</td>
                    <td>{{ v.bank_number }}</td>
                    <td>{{ v.bank_owner }}</td>
                    <td>{{ v.date }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col
            cols="4"
            sm="4"
            style="
              display: flex;
              flex-direction: column;
            "
          >
            <span>
              Keterangan
            </span>

            <span
              style="
                font-size: 12px;
                color: #808080;
              "
            >
              *Wajib diisi bila menolak
            </span>
          </v-col>

          <v-col
            cols="8"
            sm="8"
          >
            <v-textarea
              id="verifikasi-keterangan-bank"
              v-model="verifikasiKeteranganBank"
              label="Masukkan Keterangan"
              color="purple"
              no-resize
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            <v-btn
              class="text-capitalize font-common-white-bold"
              style="
                width: 100px;
                height: 46px;
                background: #7A1878;
              "
              @click="verifikasiBank(1)"
            >
              Verifikasi
            </v-btn>

            <v-btn
              class="text-capitalize font-common-white-bold"
              style="
                width: 71px;
                height: 46px;
                background: #EA3E3A;
              "
              @click="verifikasiBank(2)"
            >
              Tolak
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- IMAGE PREVIEW -->
    <div
      id="myModal"
      class="modal"
    >
      <span class="close">&times;</span>
      <img
        id="img01"
        class="modal-content"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      tab: null,
      dialogVerifikasiPersonal: false,
      dialogVerifikasiPerusahaan: false,
      dialogVerifikasiBank: false,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),

      // Personal:
      search: '',
      headers: [
        { text: 'Nama', align: 'start', value: 'name', sortable: false },
        { text: 'Status', value: 'status_string' },
        { text: 'Kota/Kab', value: 'kota_kab', sortable: false },
        { text: 'TraPoin', value: 'trapoin_saldo' },
        { text: 'Total Transaksi', value: 'trx_total' },
        { text: 'Verifikasi', value: 'verification_dat', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      customers: [],

      isAllowDetail: false,
      isAllowVerification: false,

      verifikasiHash: '',
      verifikasiNama: '',
      verifikasiEmail: '',
      verifikasiNoNIK: '',
      verifikasiKeterangan: '',
      verifikasiKTP: null,
      verifikasiSelfie: null,

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      // Perusahaan:
      searchPerusahaan: '',
      headerPerusahaan: [
        { text: 'Nama Merek', align: 'start', value: 'name_legal', sortable: false },
        { text: 'Nama Pemilik', value: 'owner_name' },
        { text: 'Status', value: 'status_string' },
        { text: 'Kota/Kab', value: 'akot_string', sortable: false },
        { text: 'TraPoin', value: 'wallet_saldo' },
        { text: 'Total Transaksi', value: 'trx_total' },
        { text: 'Verifikasi', value: 'verification_dat', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      perusahaan: [],

      pagePerusahaan: 1,
      pageCountPerusahaan: 0,
      itemCountPerusahaan: 0,
      itemTotalPerusahaan: 0,

      verifikasiPerusahaan: {
        name: '',
        name_legal: '',
        owner_name: '',
        verification_dat: {
          documents: null,
        },
      },

      verifikasiNPWP: '',
      verifikasiKeteranganPerusahaan: '',

      // Verifikasi Bank:
      searchVerifikasiBank: '',
      headerVerifikasiBank: [
        // { text: 'Tanggal Request', align: 'start', value: 'name_legal', sortable: false },
        { text: 'Nama Penyewa', value: 'name', sortable: false },
        { text: 'Nama Bank', value: 'bank_name', sortable: false },
        { text: 'Nomor Rekening', value: 'bank_number', sortable: false },
        { text: 'Atas Nama', value: 'bank_owner' },
        { text: 'Tipe', value: 'type', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      verifikasibank: [],

      pageVerifikasiBank: 1,
      pageCountVerifikasiBank: 0,
      itemCountVerifikasiBank: 0,
      itemTotalVerifikasiBank: 0,

      selectedVerifikasiBank: null,
      verifikasiKeteranganBank: '',
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      customerView () {
        let v = false
        if (this.userMenus.includes('CUSTOMER_VIEW')) v = true

        return v
      },

      customerVerification () {
        let v = false
        if (this.userMenus.includes('CUSTOMER_VERIFICATION')) v = true

        return v
      },

      customerCompanyView () {
        let v = false
        if (this.userMenus.includes('CUSTOMER_COMPANY_VIEW')) v = true

        return v
      },

      customerCompanyVerification () {
        let v = false
        if (this.userMenus.includes('CUSTOMER_COMPANY_VERIFICATION')) v = true

        return v
      },

      customerBankVerificationView () {
        let v = false
        if (this.userMenus.includes('CUSTOMER_BANK_VERIFICATION_VIEW')) v = true

        return v
      },

    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      this.getPerusahaan(1, this.itemsPerPage)
      this.getVerifikasiBank()
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/customer', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.customers = res.data.data.list
            console.log(vm.customers)
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getPerusahaan (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/customer/company', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.perusahaan = res.data.data.list

            vm.pageCountPerusahaan = res.data.data.pagination.page_total
            vm.itemCountPerusahaan = res.data.data.pagination.item_count
            vm.itemTotalPerusahaan = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getVerifikasiBank () {
        const requestBody = {
          // page: page,
          // limit: limit,
          // searchq: filter,
        }

        axios.post('/v2/t/customer/bank/verification', requestBody).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data)
            this.verifikasibank = res.data.data.list
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasiPersonal (status) {
        const reason = []
        if (status === 0) {
          for (let i = 1; i < 5; i++) {
            if (document.getElementById('alasan-' + i).checked) {
              console.log(document.getElementById('alasan-' + i).value)
              reason.push(document.getElementById('alasan-' + i).value)
            }
          }
        }
        // if (!this.verifikasiKeterangan.trim().length > 0 && status === 0) {
        //   setTimeout(() => {
        //     document.getElementById('verifikasi-keterangan').focus()
        //   }, 100)
        //   return
        // }

        const requestBody = {
          cust_hash: this.verifikasiHash,
          submit_status: status,
          no_ktp: this.verifikasiNoNIK,
          reason: reason.join(),
          // reason: this.verifikasiKeterangan,
        }

        axios.post('/v2/t/customer/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.verifikasiNoNIK = ''
            this.dialogVerifikasiPersonal = false
            this.initialize(1, this.itemsPerPage)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasiAkunPerusahaan (status) {
        if (this.verifikasiKeteranganPerusahaan.trim().length === 0 && status === 0) {
          setTimeout(() => {
            document.getElementById('verifikasi-keterangan-perusahaan').focus()
          }, 100)

          return
        }

        const docs = {
          NPWP: status,
        }

        const requestBody = {
          cust_comp_hash: this.verifikasiPerusahaan.hash,
          submit_status: status,
          no_npwp: this.verifikasiNPWP,
          doc_arr: JSON.stringify(docs),
          // keterangan: verifikasiKeteranganPerusahaan
        }

        axios.post('/v1/t/customer/company/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.verifikasiNPWP = ''
            this.verifikasiKeteranganPerusahaan = ''
            this.dialogVerifikasiPerusahaan = false
            this.initialize(1, this.itemsPerPage)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasiBank (status) {
        console.log(status)
        // if (this.verifikasiKeteranganBank.trim().length === 0 && status === 0) {
        //   setTimeout(() => {
        //     document.getElementById('verifikasi-keterangan-bank').focus()
        //   }, 100)

        //   return
        // }

        const requestBody = {
          cust_hash: this.selectedVerifikasiBank.hash,
          submit_status: status,
          cust_type: this.selectedVerifikasiBank.type_alias,
        }

        axios.post('v2/t/customer/bank/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.verifikasiKeteranganBank = ''
            this.dialogVerifikasiBank = false
            this.getVerifikasiBank()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      downloadAttachment (data) {
        // const type = data.file_type.toUpperCase()
        // if (type === 'JPG' || type === 'JPEG' || type === 'PNG' || type === 'IMAGE') {
        //   const modal = document.getElementById('myModal')
        //   const modalImg = document.getElementById('img01')
        //   modal.style.display = 'block'
        //   if (data.url) modalImg.src = data.url
        //   if (data.path) modalImg.src = data.path
        //   if (data.file_path_view_only) modalImg.src = data.file_path_view_only
        //   const span = document.getElementsByClassName('close')[0]
        //   span.onclick = function () {
        //     modal.style.display = 'none'
        //   }
        // } else if (type === 'PDF') {
        //   window.open(data.url, '_blank')
        // } else {
        //   window.open(data.url_download, '_blank')
        // }
        const modal = document.getElementById('myModal')
        const modalImg = document.getElementById('img01')
        modal.style.display = 'block'
        modalImg.src = data
        const span = document.getElementsByClassName('close')[0]
        span.onclick = function () {
          modal.style.display = 'none'
        }
      },

      showDialogVerifikasiPersonal (item) {
        // console.log(item)
        this.verifikasiNoNIK = ''

        this.verifikasiHash = item.hash
        this.verifikasiNama = item.verification_dat.name
        this.verifikasiEmail = item.verification_dat.email
        if (item.verification_dat.ktp.data !== null) this.verifikasiKTP = item.verification_dat.ktp.data.file_path
        if (item.verification_dat.ktp_selfie.data !== null) this.verifikasiSelfie = item.verification_dat.ktp_selfie.data.file_path
        this.dialogVerifikasiPersonal = true
      },

      showDialogVerifikasiPerusahaan (item) {
        // console.log(item)
        this.verifikasiPerusahaan = item
        this.dialogVerifikasiPerusahaan = true
      },

      showDialogVerifikasiBank (item) {
        // console.log(item)
        this.verifikasiKeteranganBank = ''
        this.selectedVerifikasiBank = item
        this.dialogVerifikasiBank = true
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      onChangePagePerusahaan (e) {
        this.initialize(e, this.itemsPerPage)
      },

      toPersonalDetail (item) {
        this.$router.push({ name: 'PenyewaDetail', params: { id: item.hash } })
      },

      toPersonalPerusahaanDetail (item) {
        this.$router.push({ name: 'PenyewaPerusahaanDetail', params: { id: item.hash } })
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },

      filterDataPerusahaan () {
        this.initialize(1, this.itemsPerPage, this.searchPerusahaan)
      },

      filterClearPerusahaan () {
        this.initialize(1, this.itemsPerPage)
      },

      filterDataVerifikasiBank () {
        this.initialize(1, this.itemsPerPage, this.searchVerifikasiBank)
      },

      filterClearVerifikasiBank () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 500;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */
</style>
